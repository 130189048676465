import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css'
// import './assets/css/index.css'
import request from "./utils/request";



// import VueParticles from 'vue-particles'
// Vue.use(VueParticles);

router.afterEach((to, from, next) => {

    window.scrollTo(0, 0)

});


import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import '../public/md/markdown/github-markdown.min.css';
Vue.use(mavonEditor);



// // 视频插件
// import VideoPlayer from 'vue-video-player';
// import 'video.js/dist/video-js.css';
// import 'vue-video-player/src/custom-theme.css';
//
// // import 'vue-video-player/node_modules/video.js/dist/video-js.css';
// // import 'vue-video-player/src/custom-theme.css';
//
// Vue.use(VideoPlayer);


Vue.config.productionTip = false;

Vue.use(ElementUI, { size: "mini" });

Vue.prototype.request=request



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');


// Vue.directive('title', {//单个修改标题
//     inserted: function (el, binding) {
//         document.title = el.dataset.title
//     }
// })
