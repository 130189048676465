<template>
  <div id="app">
    <router-view></router-view>
  </div>

</template>

<script>

    export default {
        name: 'App',
        created() {
            if(document.body.clientWidth<750){
                document.body.style.zoom = 0.4
                this.$message({
                    message: '温馨提示：使用电脑浏览器访问本网站，体验更佳哦！！',
                    type: 'warning',
                    duration:5000,
                    center: true
                });
            }


            // if(document.body.clientWidth<750){
            //     this.$router.replace('/mobile');
            //     //window.location.href='https://www.bilibili.com/';
            //     console.log("手机端的首页")
            // }
        //     // else {
        //     //     this.$router.replace('/')
        //     //     console.log("电脑端的首页");
        //     // }
        //     //当然随时改变屏幕大小也会改变的，所以也要加上
        //     window.addEventListener('resize',()=>{
        //         if(document.body.clientWidth<750){
        //             this.$router.replace('/mobile')
        //             //window.location.href='https://www.baidu.com/';
        //              console.log("屏幕大小改变后显示的手机端首页")
        //         }
        //         //     else {
        //         //     this.$router.replace('/')
        //         //     console.log("屏幕大小改变后显示的电脑端首页")
        //         // }
        //     })
        },

    }
</script>

<style>

</style>

