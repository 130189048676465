import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

Vue.use(VueRouter)



const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: '',
        component: () => import('../views/front/Front'),
        children: [
            {
                path: '',
                name: 'FrontHome',
                component: () => import('../views/front/Home.vue'),
                meta: {
                    title: '大神资源网-影视后期特效素材资源网'
                }
            },
            {
                path: 'front-home',
                name: 'Front-Home',
                component: () => import('../views/front/Home.vue')
            },
            {
                path: 'introduction',
                name: 'Introduction',
                component: () => import('../views/front/Introduction.vue'),
            },
            {
                path: 'articleDetail',
                name: 'ArticleDetail',
                component: () => import('../views/front/ArticleDetail.vue'),
                // meta: {
                //     title: "大神资源网-文章详情",
                // }
            },
            {
                path: 'aeSoftware',
                name: 'aeSoftware',
                component: () => import('../views/front/AE/Software.vue'),
                meta: {
                    title: '大神资源网-AE软件'
                }
            },
            {
                path: 'aePlug-in',
                name: 'aePlug-in',
                component: () => import('../views/front/AE/Plug-in.vue'),
                meta: {
                    title: '大神资源网-AE插件'
                }
            },
            {
                path: 'aeScript',
                name: 'aeScript',
                component: () => import('../views/front/AE/Script.vue'),
                meta: {
                    title: '大神资源网-AE脚本'
                }
            },
            {
                path: 'aeTutorials',
                name: 'aeTutorials',
                component: () => import('../views/front/AE/Tutorials.vue'),
                meta: {
                    title: '大神资源网-AE教程'
                }
            },
            {
                path: 'aeTemplate',
                name: 'aeTemplate',
                component: () => import('../views/front/AE/Template.vue'),
                meta: {
                    title: '大神资源网-AE模板'
                }
            },
            {
                path: 'prSoftware',
                name: 'prSoftware',
                component: () => import('../views/front/PR/Software.vue'),
                meta: {
                    title: '大神资源网-PR软件'
                }
            },
            {
                path: 'prPlug-in',
                name: 'prPlug-in',
                component: () => import('../views/front/PR/Plug-in.vue'),
                meta: {
                    title: '大神资源网-PR插件'
                }
            },
            {
                path: 'prScript',
                name: 'prScript',
                component: () => import('../views/front/PR/Script.vue'),
                meta: {
                    title: '大神资源网-PR脚本'
                }
            },
            {
                path: 'prTutorials',
                name: 'prTutorials',
                component: () => import('../views/front/PR/Tutorials.vue'),
                meta: {
                    title: '大神资源网-PR教程'
                }
            },
            {
                path: 'prTemplate',
                name: 'prTemplate',
                component: () => import('../views/front/PR/Template.vue'),
                meta: {
                    title: '大神资源网-PR模板'
                }
            },
            {
                path: '3dSoftware',
                name: '3dSoftware',
                component: () => import('../views/front/3D/Software.vue'),
                meta: {
                    title: '大神资源网-3D软件'
                }
            },
            {
                path: '3dModel',
                name: '3dModel',
                component: () => import('../views/front/3D/Model.vue'),
                meta: {
                    title: '大神资源网-3D模型'
                }
            },
            {
                path: '3dPlug-in',
                name: '3dPlug-in',
                component: () => import('../views/front/3D/Plug-in.vue'),
                meta: {
                    title: '大神资源网-3D插件'
                }
            },
            {
                path: '3dProject',
                name: '3dProject',
                component: () => import('../views/front/3D/Project.vue'),
                meta: {
                    title: '大神资源网-3D工程'
                }
            },
            {
                path: '3dTexture',
                name: '3dTexture',
                component: () => import('../views/front/3D/Texture.vue'),
                meta: {
                    title: '大神资源网-3D贴图'
                }
            },
            {
                path: '3dTutorials',
                name: '3dTutorials',
                component: () => import('../views/front/3D/Tutorials.vue'),
                meta: {
                    title: '大神资源网-3D教程'
                }
            },
            // {
            //     path: 'articleDetail',
            //     name: 'ArticleDetail',
            //     component: () => import('../views/front/ArticleDetail.vue'),
            //     meta: {
            //         title: '大神资源网-文章详情',
            //     }
            // },
            {
                path: 'blogDetail',
                name: 'BlogDetail',
                component: () => import('../views/front/BlogDetail.vue')
            },
            {
                path: 'search',
                name: 'Search',
                component: () => import('../views/front/Search.vue'),
                meta: {
                    title: '大神资源网-资源搜索'
                }
            },
            {
                path: 'front-video',
                name: 'Video',
                component: () => import('../views/front/SC/Video.vue'),
                meta: {
                    title: '大神资源网-视频背景'
                }
            },
            {
                path: 'vfx',
                name: 'VFX',
                component: () => import('../views/front/SC/VFX.vue'),
                meta: {
                    title: '大神资源网-特效素材'
                }
            },
            {
                path: 'music',
                name: 'Music',
                component: () => import('../views/front/SC/Music.vue'),
                meta: {
                    title: '大神资源网-音效素材'
                }
            },
            {
                path: 'music-pack',
                name: 'Music-Pack',
                component: () => import('../views/front/SC/Music-Pack.vue'),
                meta: {
                    title: '大神资源网-音效包素材'
                }
            },
            {
                path: 'videoDetail',
                name: 'VideoDetail',
                component: () => import('../views/front/SC/VideoDetail'),
                meta: {
                    title: '大神资源网-视频背景'
                }
            },
            {
                path: 'other',
                name: 'Other',
                component: () => import('../views/front/Other.vue'),
                meta: {
                    title: '大神资源网-其他资源'
                }
            },
            {
                path: 'frontBlog',
                name: 'Blog',
                component: () => import('../views/front/Blog.vue'),
                meta: {
                    title: '大神资源网-博客文章'
                }
            },
            {
                path: 'vip',
                name: 'VIP',
                component: () => import('../views/front/SC/Vip.vue'),
                meta: {
                    title: '大神资源网-VIP精品资源'
                }
            },
            {
                path: 'join',
                name: 'Join',
                component: () => import('../views/front/Join.vue'),
                meta: {
                    title: '大神资源网-欢迎加入'
                }
            },

        ]
    },
    {
        path: '/mobile',
        name: 'PhoneHome',
        component: () => import('../views/mobile/PhoneHome.vue')
    },

    {
        path: '/media',
        name: 'Media',
        component: () => import('../views/mobile/Media.vue')
    },
    {
        path: '/mAudio',
        name: 'Audio',
        component: () => import('../views/mobile/Audio.vue')
    },
    {
        path: '/mVideo',
        name: 'mVideo',
        component: () => import('../views/mobile/Video.vue')
    },
    {
        path: '/mBlog',
        name: 'mBlog',
        component: () => import('../views/mobile/Blog.vue')
    },

    {
        path: '/mArticleDetail',
        name: 'mArticleDetail',
        component: () => import('../views/mobile/ArticleDetail.vue')
    },
    {
        path: '/mBlogDetail',
        name: 'mBlogDetail',
        component: () => import('../views/mobile/BlogDetail.vue')
    },
    {
        path: '/login/',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {
            title: '大神资源网-登录'
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue'),
        meta: {
            title: '大神资源网-注册'
        }
    },
    {
        path: '/statement',
        name: 'Statement',
        component: () => import('../views/Statement.vue'),
        meta: {
            title: '大神资源网-声明'
        }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue'),
        meta: {
            title: '大神资源网-出错了'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 提供一个重置路由的方法
export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}

// const article = localStorage.getItem("article");
// console.log(article)


// 注意：刷新页面会导致页面路由重置
export const setRoutes = () =>{
    const storeMenus = localStorage.getItem("menus");
    // console.log(storeMenus)
    if(storeMenus){
        // 获取当前的路由对象名称数组
        const currentRouteNames = router.getRoutes().map(v => v.name)
        if (!currentRouteNames.includes('Manage')) {
            //拼装动态路由
            const manageRoute = {path: '/',name: 'Manage', component:  () => import( '../views/Manage.vue'), redirect:"/home", children:[
                    { path: '/manage/person', name: '个人信息', component: () => import('../views/Person.vue'),meta: { title: "大神资源网-个人信息"}},
                    { path: '/manage/password', name: '修改密码', component: () => import('../views/Password.vue'),meta: { title: "大神资源网-修改密码"}}
                ]}
            const menus = JSON.parse(storeMenus)
            menus.forEach(item =>{
                if(item.path){   //当且仅当path不为空的时候去设置路由
                    let itemMenu = {path: item.path.replace("/",""), name:item.name, component:  () => import( '../views/'+item.pagePath+'.vue'),meta: { title: "大神资源网-"+item.name}}
                    manageRoute.children.push(itemMenu)
                }else if(item.children.length){
                    item.children.forEach(item => {
                        if(item.path){
                            let itemMenu = {path: item.path.replace("/",""), name:item.name, component:  () => import( '../views/'+item.pagePath+'.vue'),meta: { title: "大神资源网-"+item.name}}
                            manageRoute.children.push(itemMenu)
                        }
                    })
                }

            })
            //动态添加到现在的路由对象去
            router.addRoute(manageRoute)
        }
    }
}

// 重置我就再set一次路由
setRoutes()


// 路由守卫
router.beforeEach((to, from, next) => {
    localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称，为了在Header组件中去使用
    store.commit("setPath")  // 触发store的数据更新

    // 未找到路由的情况
    if (!to.matched.length) {
        const storeMenus = localStorage.getItem("menus")
        if (storeMenus) {
            next("/404")
        } else {
            // 跳回登录页面
            next("/login")
        }
    }
    next()  // 放行路由
})


// 这个方法是为了每个页面添加属于自己的title
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})


export default router